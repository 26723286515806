import { graphql } from "gatsby";
import React from "react";
import { Trans } from "react-i18next";
import PageLayout from "../components/PageLayout";

const AccessibilityPlan: React.FC = () => {
  return (
    <>
      <PageLayout>
        <title>Accessibility Plan | PhoneBox</title>
        <div
          className="accessibility-plan"
          style={{
            padding: "60px 80px",
            backgroundColor: "white",
            maxWidth: "1300px",
            margin: "30px auto auto",
            fontSize: "16px",
            fontWeight: "400",
          }}
        >
          <h2 style={{ fontSize: "24px", fontWeight: "600" }}>
            Company Name: Connex Global Communications Inc. o/a PhoneBox
          </h2>
          <p>Date: May 1, 2024</p>

          <h3 style={{ fontSize: "20px", fontWeight: "600" }}>
            <Trans>Accessibility Plan</Trans>
          </h3>
          <ol>
            <li>
              <p>
                <strong>
                  <Trans>Introduction</Trans>
                </strong>
              </p>
              <p>
                <Trans>
                  Connex Global Communications Inc. o/a PhoneBox is committed to
                  fostering an inclusive and accessible environment for all
                  individuals, including customers, employees, and stakeholders.
                  In alignment with the Accessible Canada Act (ACA), we aim to
                  identify, remove, and prevent barriers to accessibility across
                  our organization.
                </Trans>
              </p>
            </li>
            <li>
              <p>
                <strong>
                  <Trans>Statement of Commitment</Trans>
                </strong>
              </p>
              <p>
                <Trans>
                  We are dedicated to treating all individuals with dignity and
                  respect. Our commitment involves integrating accessibility
                  into our policies, practices, and services to ensure equitable
                  access and opportunities for persons with disabilities.
                </Trans>
              </p>
            </li>
            <li>
              <p>
                <strong>
                  <Trans>Areas of Focus</Trans>
                </strong>
              </p>
              <ol type="A">
                <li>
                  <p>
                    <strong>
                      <Trans>Employment</Trans>
                    </strong>
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>
                          <Trans>Inclusive Recruitment: </Trans>
                        </strong>{" "}
                        <Trans>
                          Implement hiring practices that attract and
                          accommodate candidates with disabilities.
                        </Trans>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>
                          <Trans>Training and Development: </Trans>
                        </strong>{" "}
                        <Trans>
                          Provide accessibility awareness training to all
                          employees, ensuring a culture of inclusivity.
                        </Trans>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>
                          <Trans>Feedback Mechanisms: </Trans>
                        </strong>{" "}
                        <Trans>
                          Establish channels for employees to provide feedback
                          on accessibility-related matters.
                        </Trans>
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    <strong>
                      <Trans>Built Environment</Trans>
                    </strong>
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>
                          <Trans>Accessibility Audits: </Trans>
                        </strong>{" "}
                        <Trans>
                          Regularly assess our physical spaces to identify and
                          address barriers.
                        </Trans>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>
                          <Trans>Universal Design Principles: </Trans>
                        </strong>{" "}
                        <Trans>
                          Incorporate accessibility features in all renovations
                          and new constructions.
                        </Trans>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>
                          <Trans>Emergency Procedures: </Trans>
                        </strong>{" "}
                        <Trans>
                          Develop and communicate emergency plans that consider
                          the needs of persons with disabilities.
                        </Trans>
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    <strong>
                      <Trans>
                        Information and Communication Technologies (ICT)
                      </Trans>
                    </strong>
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>
                          <Trans>Digital Accessibility: </Trans>
                        </strong>{" "}
                        <Trans>
                          Ensure our websites and digital platforms comply with
                          WCAG 2.1 Level AA standards.
                        </Trans>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>
                          <Trans>Accessible Formats: </Trans>
                        </strong>{" "}
                        <Trans>
                          Provide information in alternative formats upon
                          request, such as braille, large print, or audio.
                        </Trans>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>
                          <Trans>Assistive Technologies: </Trans>
                        </strong>{" "}
                        <Trans>
                          Support the use of assistive devices and technologies
                          to enhance user experience.
                        </Trans>
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    <strong>
                      <Trans>Communication, Other Than ICT</Trans>
                    </strong>
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>
                          <Trans>Customer Service Training: </Trans>
                        </strong>{" "}
                        <Trans>
                          Equip frontline staff with the skills to effectively
                          communicate with individuals with diverse needs.
                        </Trans>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>
                          <Trans>Alternative Communication Methods: </Trans>
                        </strong>{" "}
                        <Trans>
                          Offer services like sign language interpretation and
                          real-time captioning when required.
                        </Trans>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>
                          <Trans>Accessible Documentation: </Trans>
                        </strong>{" "}
                        <Trans>
                          Ensure all public documents are available in
                          accessible formats.
                        </Trans>
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    <strong>
                      <Trans>
                        Procurement of Goods, Services, and Facilities
                      </Trans>
                    </strong>
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>
                          <Trans>Supplier Engagement: </Trans>
                        </strong>{" "}
                        <Trans>
                          Prioritize vendors who demonstrate a commitment to
                          accessibility.
                        </Trans>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>
                          <Trans>Accessibility Criteria: </Trans>
                        </strong>{" "}
                        <Trans>
                          Integrate accessibility requirements into procurement
                          processes and contracts.
                        </Trans>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>
                          <Trans>Ongoing Evaluation: </Trans>
                        </strong>{" "}
                        <Trans>
                          Regularly assess procured goods and services for
                          accessibility compliance.
                        </Trans>
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    <strong>
                      <Trans>
                        Design and Delivery of Programs and Services
                      </Trans>
                    </strong>
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>
                          <Trans>User Consultation: </Trans>
                        </strong>{" "}
                        <Trans>
                          Engage persons with disabilities in the development
                          and evaluation of our services.
                        </Trans>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>
                          <Trans>Continuous Improvement: </Trans>
                        </strong>{" "}
                        <Trans>
                          Solicit feedback to enhance service accessibility and
                          address identified barriers promptly.
                        </Trans>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>
                          <Trans>Staff Training: </Trans>
                        </strong>{" "}
                        <Trans>
                          Provide specialized training to employees involved in
                          program and service delivery.
                        </Trans>
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    <strong>
                      <Trans>Transportation (If applicable)</Trans>
                    </strong>
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>
                          <Trans>Accessible Fleet: </Trans>
                        </strong>{" "}
                        <Trans>
                          Ensure company vehicles are equipped to accommodate
                          individuals with mobility challenges.
                        </Trans>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>
                          <Trans>Driver Training: </Trans>
                        </strong>{" "}
                        <Trans>
                          Train drivers on assisting passengers with
                          disabilities safely and respectfully.
                        </Trans>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>
                          <Trans>Scheduling Accommodations: </Trans>
                        </strong>{" "}
                        <Trans>
                          Offer flexible scheduling options to meet diverse
                          transportation needs.
                        </Trans>
                      </p>
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
            <li>
              <p>
                <strong>
                  <Trans>Feedback Mechanism</Trans>
                </strong>
              </p>
              <p>
                <Trans>
                  We value input from all stakeholders to enhance our
                  accessibility efforts. Feedback can be provided through:
                </Trans>
              </p>
              <ul>
                <li>
                  <p>
                    <strong>
                      <Trans>Email: </Trans>
                    </strong>
                    <a href="mailto:info@phonebox.com">info@phonebox.com</a>
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      <Trans>Phone: </Trans>
                    </strong>
                    <a href="tel:1-855-886-0505">1-855-886-0505</a>
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      <Trans>Mail: </Trans>
                    </strong>
                    <br />
                    Accessibility Coordinator, Connex Global Communications Inc.
                    <br />
                    o/a PhoneBox, <br />
                    658 Seymour Street, Vancouver, <br />
                    British Columbia, Canada V6B 3K4
                  </p>
                </li>
              </ul>
              <p>
                <Trans>
                  All feedback will be acknowledged within five business days,
                  and appropriate actions will be taken to address concerns.
                </Trans>
              </p>
            </li>
            <li>
              <p>
                <strong>
                  <Trans>Implementation and Monitoring</Trans>
                </strong>
              </p>
              <ul>
                <li>
                  <p>
                    <strong>
                      <Trans>Accessibility Coordinator: </Trans>
                    </strong>{" "}
                    <Trans>
                      Appoint a dedicated individual to oversee the execution
                      and monitoring of accessibility initiatives.
                    </Trans>
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      <Trans>Regular Reviews: </Trans>
                    </strong>{" "}
                    <Trans>
                      Conduct annual assessments of our accessibility plan to
                      measure progress and make necessary adjustments.
                    </Trans>
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      <Trans>Reporting: </Trans>
                    </strong>{" "}
                    <Trans>
                      Publish annual progress reports and notify the
                      Accessibility Commissioner and the Canadian
                      Radio-television and Telecommunications Commission (CRTC)
                      as required.
                    </Trans>
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>
                <strong>
                  <Trans>Contact Information</Trans>
                </strong>
              </p>
              <p>
                <Trans>
                  For inquiries or more information about this accessibility
                  plan, please contact:
                </Trans>
              </p>
              <ul>
                <li>
                  <p>
                    <strong>
                      <Trans>Email: </Trans>
                    </strong>
                    <a href="mailto:info@phonebox.com">info@phonebox.com</a>
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      <Trans>Phone: </Trans>
                    </strong>
                    <a href="tel:1-855-886-0505">1-855-886-0505</a>
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      <Trans>Mail: </Trans>
                    </strong>
                    <br />
                    Accessibility Coordinator, Connex Global Communications Inc.
                    <br />
                    o/a PhoneBox, <br />
                    658 Seymour Street, Vancouver, <br />
                    British Columbia, Canada V6B 3K4
                  </p>
                </li>
              </ul>
              <p>
                <Trans>
                  This plan will be reviewed and updated regularly to reflect
                  our ongoing commitment to accessibility and to incorporate
                  feedback from our community.
                </Trans>
              </p>
            </li>
          </ol>
        </div>
      </PageLayout>
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default AccessibilityPlan;
